import React from 'react'

function Footer() {
    return (
        <div className="footer">
            <p>The FoxSeq® service mark and logo are registered trademarks owned by BioInfoExperts LLC</p>
        </div>
    )
}

export default Footer;