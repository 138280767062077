import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
// import { hospitalHelper, checkAddFile } from '../Helpers';

function HospitalMenu({roles, selectHospital, updateHospital}) {
    // const [open, setOpen] = useState(false);
    // const [label, setLabel] = useState('');
    const [selection, setSelection] = useState(roles[0]);

    const handleSelection = (selected) => {
        // console.log(selection);
        setSelection(selected);
        // function from parent to set hospital selection
        selectHospital(selected);
        // hospitalHelper(selection.name);
        updateHospital(selected.name);
        // checkAddFile(selected.name);
    };

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle>{selection.label}</Dropdown.Toggle>
                <Dropdown.Menu>
                    {roles.map((role) => {
                        // console.log(role);
                        return (
                            <Dropdown.Item onClick={() => handleSelection(role)} key={role.label}  >{role.label}</Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default HospitalMenu;