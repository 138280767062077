import React, { useRef, useState, useEffect } from 'react';
import { uploadFastqFile, uploadSeqFile, uploadClientFile, uploadAddFile, checkStandard } from './Helpers';
import './App.css';
import logo from './assets/FoxSeq_logo-1.png';
import SuccessAlert from './SuccessAlert';
import { useAuth0 } from '@auth0/auth0-react';
import Profile from './components/Profile';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
import { getTemplate, hospTempFile, checkAddFile, hospAddFile } from './Helpers';
// import { BiSupport } from 'react-icons/bi'

let outsideSetAdditional
let outsideSetStandard

export function setVis(bool) {
    outsideSetAdditional(bool);
}
export function setStand(bool) {
    outsideSetStandard(bool);
}

function App({show}) {
    const fileRef = useRef({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [fastqName, setFastqName] = useState('');
    const [fastaName, setFastaName] = useState('');
    const [clientName, setClientName] = useState('');
    const [addName, setAddName] = useState('');
    const { loginWithRedirect, isAuthenticated, logout, isLoading } = useAuth0();
    const [selector, setSelector] = useState('Files');
    const [hospital, setHospital] = useState('');
    const [additional, setAdditional] = useState(false);
    const [standard, setStandard] = useState(false);

    outsideSetAdditional = setAdditional;
    outsideSetStandard = setStandard

    useEffect(() => {
        checkAddFile(hospital);
        checkStandard(hospital);
    }, [hospital])
    // create state variable selector to select between files and folders

    // if (!show) {
    //     return null
    // };

    // Takes in the files added to input in Fastq format to upload to Pipeline
    // Uses a helper function from Helpers in Header component to upload to AWS
    // Uploads to specific hospital depending on which hospital is selected in the Header by username/user email
    const fileSubmit = (event) => {
        event.preventDefault();
        // console.log(fileRef)
        // console.log(fileRef.current)
        // console.log(fileRef.current['fastq'].files)
        // console.log(fileRef.current['sequences'].files)
        let fileArr = fileRef.current['fastq'].files;
        uploadFastqFile(fileArr, hospital)
        setFastqName('')
        // for (let i = 0; i < fileArr.length; i++) {
        //     uploadFastqFile(fileArr[i])
        // }
    }

    // Similar to the Fastq except it only takes a single Fasta file
    // Uses Helper function that is slightly different to fileSubmit function Helper (goes to different aws bucket)
    const fileSeqSubmit = (event) => {
        event.preventDefault();
        let fileArr = fileRef.current['sequences'].files;
        for (let i = 0; i < fileArr.length; i++) {
            uploadSeqFile(fileArr[i], hospital);
        }
        setFastaName('')
    }

    const fileClientSubmit = (event) => {
        event.preventDefault();
        let fileArr = fileRef.current['client'].files;
        for (let i = 0; i < fileArr.length; i++) {
            uploadClientFile(fileArr[i], hospital);
        }
        setClientName('')
    }
    
    const fileAddSubmit = (event) => {
        event.preventDefault();
        let fileArr = fileRef.current['add'].files;
        for (let i = 0; i < fileArr.length; i++) {
            uploadAddFile(fileArr[i], hospital);
        }
        setAddName('')
    }

    const login = () => {
        loginWithRedirect();
        // setAuthorize(true);
    }

    const onChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            // setFastqName(e.target.files[0]);
            let fileArr = [];
            for (let i = 0; i < e.target.files.length; i++) {
                fileArr.push(e.target.files[i]);
            }
            setFastqName(fileArr);
            // console.log(fileRef.current['fastq'].files);
            // console.log(e.target.files)
            // console.log(fastqName);
            // console.log(fastqName.length);
        }
    }

    const onChangeSeq = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFastaName(e.target.files[0]);
        }
    }

    const onChangeClient = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setClientName(e.target.files[0]);
        }
    }

    const onChangeAdd = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setAddName(e.target.files[0]);
        }
    }

    if (!isAuthenticated) {
        return (
            <div className="signin_page">
                <img className="logo_signin" src={logo} alt="logo_foxseq" />
                <h3>Welcome to the FoxSeq Data Upload Portal</h3>
                <p>Please login to upload your data to the Secure BIE Cloud</p>
                {/* <button onClick={login}  >Enter FoxComputation</button> */}
                <Button variant="primary" onClick={login} >Enter FoxComputation</Button>
                <Footer />
            </div>
        )
    }

    if (isAuthenticated && !isLoading) {
        // console.log(user);
        // hospitalHelper(user['https://www.bioinfox.com/app_metadata'].roles[0].name);
        // console.log(user['https://www.bioinfox.com/app_metadata'].roles[0].name)
        // setHospital(user['https://www.bioinfox.com/app_metadata'].roles[0].name)
        // checkAddFile(hospital);
    }

    return (
        <div className="upload">
            <div className="header">
                <img className="logo" src={logo} alt="logo_foxseq" />
                <Button variant="danger" style={{ float: 'right' }} onClick={() => logout({ returnTo: window.location.origin })}>Log Out</Button>
                {/* <button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button> */}
                <Profile 
                    updateHospital={setHospital}
                />
            </div>
            <SuccessAlert 
                showSuccess={showSuccess}
            />
            {/* <br></br>
            <br></br> */}
            <div className="file-inputs">
                <h3 className="title">{`Upload Raw Fastq ${selector} to Pipeline`}</h3>
                    <div className="selector_drop">
                        <Dropdown className="selector">
                            <Dropdown.Toggle >{selector}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setSelector('Files')}>Files</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSelector('Folders')}>Folders</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {selector === 'Files' ? 
                            <input className="input_fastq" type="file" accept=".fastq, .gz" multiple 
                            ref={el => fileRef.current['fastq'] = el}
                            onChange={onChange}
                            /> : 
                            <input className="input_fastq" type="file" accept=".fastq, .gz" multiple webkitdirectory="true" directory="true"
                            ref={el => fileRef.current['fastq'] = el}
                            onChange={onChange}
                            />}
                    
                        <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['fastq'].click()} >{`Choose Fastq ${selector}`}</Button>
                    {/* <button
                        className="upload-btn"
                        onClick={() => fileRef.current['fastq'].click()}
                        >Choose Fastq Files
                    </button> */}
                        {fastqName === '' ? null : fastqName.length >= 2 ? `${fastqName.length} Files...` : fastqName[0].name}
                        <Button className="submit" type="submit" onClick={fileSubmit} >Start Upload</Button>
                    </div>
                {/* <button className="submit" type="submit" onClick={fileSubmit} >Start Upload</button> */}
                {/* <br></br> */}
                <br></br>
                <h3 className="title">Upload Fasta Sequence File to Pipeline</h3>
                <input className="input_seq" type="file" accept=".fasta, .fas, .fa" ref={el => fileRef.current['sequences'] = el} onChange={onChangeSeq} />
                <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['sequences'].click()} >Choose Fasta File</Button>
                {/* <button className="submit" type="submit" onClick={fileSeqSubmit} >Start Upload</button> */}
                {fastaName === '' ? null : fastaName.name}
                <Button className="submit" type="submit" onClick={fileSeqSubmit} >Start Upload</Button>
                <br></br>
                <br></br>
                <h3 className="title">Upload Metadata File (Standard)</h3>
                <input className="input_client" type="file" accept=".xls, .xlsx" ref={el => fileRef.current['client'] = el} onChange={onChangeClient} />
                <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['client'].click()} >Choose File</Button>
                {/* <button className="submit" type="submit" onClick={fileClientSubmit} >Start Upload</button> */}
                {clientName === '' ? null : clientName.name}
                <Button className="submit" type="submit" onClick={fileClientSubmit} >Start Upload</Button>
                {/* <Button className="template-btn" variant="primary" onClick={() => getTemplate()} >Get Template</Button> */}
                {/* <p>Download Template File</p> */}
                <br></br>
                <br></br>
                <h3 className="title">Upload Metadata File (Custom)</h3>
                <input className="input_add" type="file" accept=".csv, .xls, .xlsx" ref={el => fileRef.current['add'] = el} onChange={onChangeAdd} />
                <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['add'].click()} >Choose File</Button>
                {addName === '' ? null : addName.name}
                <Button className="submit" type="submit" onClick={fileAddSubmit} >Start Upload</Button>
                <br></br>
                <br></br>
                <h3 className="title">Download Project Metadata Files</h3>
                {standard === false && additional === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {standard === true ? <Button className="hosp_temp" variant="primary" onClick={() => hospTempFile(hospital)} >Hospital Metadata (Standard)</Button> : null}
                {additional === true ? <Button className="add-btn" variant="primary" onClick={() => hospAddFile(hospital)} >Hospital Metadata (Custom)</Button> : null }
                <br></br>
                <br></br>
                <h3 className="title">Download Template Metadata File</h3>
                <Button className="template-btn" variant="primary" onClick={() => getTemplate()} >New Template (Standard)</Button>
            </div>
            <div className="support" title="Contact Us" >
                {/* <BiSupport size={32} onClick={() => document.getElementById("support_email").click()} /> */}
                <Button className="support_btn" variant="info" onClick={() => document.getElementById('support_email').click()} >Contact Us</Button>
                <a href="mailto:support@bioinfox.com" id="support_email" style={{ display: 'none' }}> </a>
            </div>
            <Footer />
        </div>
    )
}

export default App;
