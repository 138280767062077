import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

let outsideSetShow;
let fileName;
export function handleSuccess(name) {
    fileName = name;
    outsideSetShow(true);
    setTimeout(() => {
        outsideSetShow(false)
    }, 2000)
}

function SuccessAlert({ showSuccess }) {
    const [show, setShow] = useState(false);

    outsideSetShow = setShow

    useEffect(() => {
        setShow(showSuccess);
    }, [showSuccess]);

    return (
        <div className="successAlertMessage">
            {show ? 
            <Alert variant="success" show={show} onClose={() => setShow(!show)} dismissible style={{ zIndex: 100, marginLeft: '15%', width: '75%'}} >
                <Alert.Heading style={{ textAlign: 'center', fontSize: '25px' }} >Successfully Uploaded</Alert.Heading>
                <p style={{ textAlign: 'center', fontSize: '20px' }}>{fileName} has been successfully uploaded to AWS</p>
            </Alert> : null }
        </div>
    )
}

export default SuccessAlert;