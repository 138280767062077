import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react'
// import Popover from 'react-bootstrap/Popover';
import HospitalMenu from './HospitalMenu';

function Profile ({updateHospital}) {
    const { user, isAuthenticated } = useAuth0();
    const [hospital, setHospital] = useState(user['https://www.bioinfox.com/app_metadata'].roles[0].name)

    useEffect(() => {
        updateHospital(hospital);
        // eslint-disable-next-line
    }, []);

    const selectHospital = (selection) => {
        // helper function to set hospital for uploading
        setHospital(selection.name);
    }

    return (
        isAuthenticated && (
            <div style={{  flexDirection: 'column', alignItems: 'center', float: 'right', padding: '5px' }}>
                <p>{user.email}</p>
                <HospitalMenu 
                    roles={user['https://www.bioinfox.com/app_metadata'].roles}
                    selectHospital={selectHospital}
                    updateHospital={updateHospital}
                />
            </div>
        )
    )
}

export default Profile;