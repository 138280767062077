import { handleSuccess } from "./SuccessAlert"
import { setStand, setVis } from "./App";
var fileDownload = require('js-file-download');
const AWS = require('aws-sdk');
// const fs = require('fs');

// let hospital = 'hospital1';
// let sHosp = 'H1';
// function hospitalHelper(input) {
//     hospital = input;
//     console.log(hospital)
//     sHospHelp(input);
// }

function sHospHelp(hospital) {
  let store = hospital.slice(8);
  // console.log(store);
  return 'H' + store;
  
}

// let urls = {};
// function getUrls (urlObject) {
//   // urls = urlObject;
//   // loop through JSON object and get links and add to urls object
//   for (let key in urlObject.urls) {
//     urls[key] = urlObject.urls[key];
//   }

// }

let BUCKET_NAME = "foxseqiofiles"
AWS.config.update(
    {
      accessKeyId: "AKIA5GVJ7ECRUKOOVIWN",
      secretAccessKey: "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",
    }
);

// const getHospital = (input) => {
//   hospital = input;
//   hospitalHelper(hospital);
//   getLinks(hospital);
// };

const uploadFastqFile = (fileName, hospital) => {
    let s3 = new AWS.S3();
    // const fileContent = fs.readFileSync(fileName);
    for (let i = 0; i < fileName.length; i++) {
      console.log(fileName);
      console.log(fileName[i])
      s3.putObject({
        Bucket: 'foxseq-input',
        Key: `${hospital}/${fileName[i].name}`,
        Body: fileName[i],
        ServerSideEncryption: "AES256"
      }, function (error, data) {
        if (error) {
          console.error(error);
        } else {
          console.log('File successfully uploaded: ' + data);
          handleSuccess(fileName[i].name);
        }
      })
    }
    // s3.putObject({
    //   Bucket: BUCKET_NAME, // testing use 'foxseqiofiles-test'
    //   Key: `inputs/${hospital}/${fileName.name}`, // specific name to give the file
    //   Body: fileName
    // }, function(error, data) {
    //   if (error) {
    //     console.error('File encountered error: ' + error);
    //   } else {
    //     console.log('File successfully uploaded: ' + data);
    //     handleSuccess();
    //   }
    // })
  };
  
  const uploadSeqFile = (fileName, hospital) => {
    let s3 = new AWS.S3();
    // const fileContent = fs.readFileSync(fileName);
    s3.putObject({
      Bucket: "mutation-tracker",
      Key: `inputs/${hospital}/${fileName.name}`,
      Body: fileName,
      ServerSideEncryption: "AES256"
    }, function(error, data) {
      if (error) {
        console.error('File encountered error: ' + error);
      } else {
        console.log('File successfully uploaded: ' + data);
        handleSuccess(fileName.name);
      }
    })
  };
  
  const uploadClientFile = (fileName, hospital) => {
    let sHosp = sHospHelp(hospital);
    let s3 = new AWS.S3();
    s3.putObject({
      Bucket: "bie-samples",
      Key: `${hospital}/UPLOAD_DATA_${sHosp}.xlsx`,
      Body: fileName,
      ServerSideEncryption: "AES256"
    }, function (error, data) {
      if (error) {
        console.error("File Encountered error: " + error);
      } else {
        console.log('File uploaded successfully: ' + data);
        handleSuccess(fileName.name);
      }
    })
  }

  const uploadAddFile = (fileName, hospital) => {
    let sHosp = sHospHelp(hospital);
    let s3 = new AWS.S3();
    s3.putObject({
      Bucket: 'bie-samples',
      Key: `${hospital}/CUSTOM_METADATA_${sHosp}.xlsx`,
      Body: fileName,
      ServerSideEncryption: "AES256"
    }, function (error, data) {
      if (error) {
        console.error(error);
      } else {
        console.log('File uploaded successfully: ' + data);
        handleSuccess(fileName.name);
      }
    })
  }

  const getTemplate = () => {
    let s3 = new AWS.S3()
    s3.getObject({
      Bucket: "bie-samples",
      Key: "template/UPLOAD_DATA_template.xlsx"
    }, function (error, data) {
      if (error) {
        console.error("File Encountered error: " + error);
      } else {
        // console.log(data);
        let blob = new Blob([data.Body], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        // console.log(blob)
        let file = new File([blob], 'UPLOAD_DATA_template.xlsx', { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        fileDownload(file, "UPLOAD_DATA_template.xlsx");
        // let reader = new FileReader(blob);
        // reader.onload = function(f) {
        //   // console.log(f.target.result);
        //   fileDownload(f.target.result, "template.xlsx")
        // }
        // reader.readAsArrayBuffer(blob);
      }
    })
  }

  const hospTempFile = (hospital) => {
    let sHosp = sHospHelp(hospital);
    let s3 = new AWS.S3();
    s3.getObject({
      Bucket: "bie-samples",
      Key: `${hospital}/UPLOAD_DATA_${sHosp}.xlsx`
    }, function (error, data) {
      if (error) {
        console.error(error);
      } else {
        let blob = new Blob([data.Body], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let file = new File([blob], 'hospital_template.xlsx', { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        fileDownload(file, `UPLOAD_DATA_${sHosp}.xlsx`)
      }
    })
  }

  const hospAddFile = (hospital) => {
    let sHosp = sHospHelp(hospital);
    let s3 = new AWS.S3();
    s3.getObject({
      Bucket: "bie-samples",
      Key: `${hospital}/CUSTOM_METADATA_${sHosp}.xlsx`
    }, function (error, data) {
      if (error) {
        console.error(error);
      } else {
        let blob = new Blob([data.Body], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let file = new File([blob], 'hospital_additonal.xlsx', { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        fileDownload(file, `CUSTOM_METADATA_${sHosp}.xlsx`);
      }
    })
  }

  const checkAddFile = (hospital) => {
    // let sHosp = sHospHelp(hospital);
    let s3 = new AWS.S3();
    s3.listObjectsV2({
      Bucket: "bie-samples",
      Prefix: `${hospital}/CUSTOM_METADATA`
    }, function (error, data) {
      if (error) {
        console.error(error);
        setVis(false);
      } else {
        if (data.Contents.length >= 1) {
          setVis(true);
          // console.log(data);
          // console.log(data.Contents);
        } else {
          setVis(false);
        }
      }
    })
  }

  const checkStandard = (hospital) => {
    let s3 = new AWS.S3();
    s3.listObjectsV2({
      Bucket: "bie-samples",
      Prefix: `${hospital}/UPLOAD_DATA`
    }, function (error, data) {
      if (error) {
        console.error(error);
        setStand(false);
      } else {
        if (data.Contents.length >= 1) {
          setStand(true);
        } else {
          setStand(false);
        }
      }
    })
  }

  export { uploadFastqFile, uploadSeqFile, uploadClientFile, getTemplate, hospTempFile, uploadAddFile, checkAddFile, hospAddFile, checkStandard }